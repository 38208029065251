import { STEPS } from '../steps.js';

const AddStep = ({image, title, description}) => (
	<div className="stage">
		<img src={image} alt={title} className='stageImage'/>
		<div>
			<h3 className="subtitleLight">{title}</h3>
			<hr className="hr"/>
			<p className="baseFontSpacing description">{description}</p>
		</div>
	</div>
);

const Etapas = ({reference}) => {	
	return (
		<section className='section' ref={reference}>
			<h2 className='headline underline'>Soluciones hechas a tu medida</h2>
			<div className='stageList'>
				{STEPS.map((step) => (
					<AddStep key={step.id} {...step}/>
				))}
			</div>
			<div className="textAlignEnd"><button className="button shadow">¿Quieres dar el siguiente paso?</button></div>
		</section>
	);
}

export default Etapas;