import step1 from './assets/images/step1.svg';
import step2 from './assets/images/step2.svg';
import step3 from './assets/images/step3.svg';
import step4 from './assets/images/step4.svg';
import step5 from './assets/images/step5.svg';

export const STEPS = [
	{
		id: 1151161,
		image: step1,
		title: 'Paso inicial',
		description: 'Comenzamos por escucharte atentamente. Tu negocio es único, y entendemos que las soluciones deben adaptarse a tus necesidades específicas.',
	},
	{
		id: 1151162,
		image: step2,
		title: 'Avance',
		description: 'Transformamos tu visión en realidad. Nuestro equipo se dedica a crear soluciones tecnológicas a medida, diseñadas para impulsar tu éxito.',
	},
	{
		id: 1151163,
		image: step3,
		title: 'Progreso',
		description: 'No solo creamos soluciones, sino que también compartimos nuestro conocimiento contigo. Te hacemos autosuficiente, para que te sientas cómodo gestionando tu propio éxito.',
	},
	{
		id: 1151164,
		image: step4,
		title: 'Resultados',
		description: 'Trabajamos en conjunto para optimizar tu modelo de negocio. Nuestro enfoque es encontrar las rutas más eficientes hacia tu crecimiento.',
	},
	{
		id: 1151165,
		image: step5,
		title: 'Conclusión',
		description: 'Estamos aquí para potenciar tu negocio con tecnología que se adapta a ti. Tu éxito es nuestro mayor logro, y estamos comprometidos a lograrlo juntos.',
	},
];