import member1 from './assets/images/img-rafa.png';
import member2 from './assets/images/img-nat.png';
import member3 from './assets/images/img-alma.png';
import member4 from './assets/images/img-ale.png';
import member5 from './assets/images/img-bruno.png';

export const TEAM = [
	{
		id: 1161011,
		image: member1,
		title: 'Rafa',
		description: '“Somos un grupo de personas apasionadas por la tecnología y la innovación.”',
	},
	{
		id: 1161012,
		image: member2,
		title: 'Nat',
		description: '“Somos un grupo de personas apasionadas por la tecnología y la innovación.”',
	},
	{
		id: 1161013,
		image: member3,
		title: 'Alma',
		description: '“Somos un grupo de personas apasionadas por la tecnología y la innovación.”',
	},
	{
		id: 1161014,
		image: member4,
		title: 'Ale',
		description: '“Somos un grupo de personas apasionadas por la tecnología y la innovación.”',
	},
	{
		id: 1161015,
		image: member5,
		title: 'Bruno',
		description: '“Somos un grupo de personas apasionadas por la tecnología y la innovación.”',
	},
];