import './App.css';
import Navbar from './components/Navbar.jsx';
import Inicio from './components/Inicio.jsx';
import Etapas from './components/Etapas.jsx';
import Servicios from './components/Servicios.jsx';
import Herramientas from './components/Herramientas.jsx';
import Equipo from './components/Equipo.jsx';
import Contacto from './components/Contacto.jsx';
import {useRef} from 'react';
import Footer from './components/Footer.jsx';


function App() {
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const ref5 = useRef(null);
	const ref6 = useRef(null);

	const sta1 = () => {
		if (ref1.current) {
			ref1.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const sta2 = () => {
		if (ref2.current) {
			ref2.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const sta3 = () => {
		if (ref3.current) {
			ref3.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const sta4 = () => {
		if (ref4.current) {
			ref4.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const sta5 = () => {
		if (ref5.current) {
			ref5.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const sta6 = () => {
		if (ref6.current) {
			ref6.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

  return (
	<div>
		<Navbar onClick1={sta1} onClick2={sta2} onClick3={sta3} onClick4={sta4} onClick5={sta5} onClick6={sta6}/>
		<div className='content'>
			<Inicio reference={ref1} />
			<Etapas reference={ref2} />
			<Servicios reference={ref3} />
			<Herramientas reference={ref4}/>
			<Equipo reference={ref5}/>
			<Contacto reference={ref6}/>
			<Footer />
		</div>
	</div>
	
  );
}

export default App;
