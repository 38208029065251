import imgContacto from '../assets/images/contacto.svg';
import linkedin from '../assets/images/linkedin.svg';
import instagram from '../assets/images/instagram.svg';
import logoFooter from '../assets/images/logo-footer.svg';
import { useForm } from "react-hook-form"
import React from 'react';

function Contacto ({reference}) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm()

	const checked = watch("politica");

	const onSubmit = async (data) => {
		// try {
		// 	await axios.post('http://localhost:3001/send-email', data);
		// 	alert('Formulario enviado con éxito');
		// } catch (error) {
		// 	console.error('Error al enviar el formulario:', error);
		// 	alert('Hubo un error al enviar el formulario');
		// }
		console.log(data);
	};

	const onClick = async (data) => {
		// try {
		// 	await axios.post('http://localhost:3001/send-email', data);
		// 	alert('Formulario enviado con éxito');
		// } catch (error) {
		// 	console.error('Error al enviar el formulario:', error);
		// 	alert('Hubo un error al enviar el formulario');
		// }
		console.log("Política de privacidad");
	};

	return (
		<section className='section paddingBlockStart paddingBlockEnd backgroundWhite' ref={reference}>
			<div className='flex'>
				<div>
					<h2 className='headline textDark underline'>Hagamos magia juntos</h2>
					<p className='baseFont dark paragraph small'>Estamos aquí para hacer que tu proyecto cobre vida. Completa el formulario y dinos más sobre lo que necesitas. Estamos emocionados por la oportunidad de colaborar contigo.</p>
					<div className="textAlignEnd">
						<img src={imgContacto} alt="Coding" />
					</div>
					<h2 className='subtitleLight textDark'>Contáctanos</h2>
					<hr className='hr'/>
					<div className='contactInfo'>
						<p className='baseFont textDark marginInlineStart'>contacto@guiobajo13.com</p>
						<p className='baseFont textDark marginInlineStart'>+52 55 5469 3465</p>
					</div>
					<div className="row">
						<img src={linkedin} alt="LinkedIn" />
						<img src={instagram} alt="Instagram" />
						<img src={logoFooter} alt="Logotipo azul" />
					</div>
				</div>
				<form className="form" onSubmit={handleSubmit(onSubmit)} ref={reference}>
					<div className="inputContainer">
						<label className="label" htmlFor="nombre">Nombre</label>
						<input className="wide" {...register("nombre", { required: true })} type="text" placeholder="Escribe tu nombre" name="nombre" id="nombre"/>
						{errors.nombre && <span className="supportText error">Este campo es requerido</span>}
					</div>
					<div className="inputContainer">
						<label className="label" htmlFor="email">Email</label>
						<input className="wide" {...register("email", { required: true })} type="email" placeholder="tucorreo@mail.com" name="email" id="email"/>
						{errors.email && <span className="supportText error">Este campo es requerido</span>}
					</div>
					<div className="inputContainer">
						<label className="label" htmlFor="empresa">Empresa</label>
						<input className="wide" {...register("empresa", { required: true })} type="text" placeholder="Tu empresa" name="empresa" id="empresa"/>
						{errors.empresa && <span className="supportText error">Este campo es requerido</span>}
					</div>
					<div className="inputContainer">
						<label className="label" htmlFor="numero">Número</label>
						<input className="wide" {...register("numero", { required: true })} type="tel" placeholder="+52 55 964 1245" name="numero" id="numero"/>
						{errors.numero && <span className="supportText error">Este campo es requerido</span>}
					</div>
					<div className="inputContainer">
						<label className="label" htmlFor="proyecto">Tu proyecto</label>
						<textarea className="wide" {...register("proyecto", { required: true })} placeholder="" name="proyecto" id="proyecto"></textarea>
						<span className="supportText">Explícanos un poco más de tu emocionante proyecto y si gustas, adjunta un archivo.</span>
						<br />
						{errors.proyecto && <span className="supportText error">Este campo es requerido</span>}
					</div>
					<div className="inputContainer">
						<input className="inputFile" type="file" name="attachment" {...register("adjunto",  {})} id="adjunto"/>
						<span className="supportText">Por favor carga un archivo con las siguientes extensiones: .docx, .pdf, .txt</span>
					</div>
					<div className="row">
						<div className="inputContainer">
							<input type='checkbox' {...register('politica', { required: true })} className='checkbox' name="politica" id="politica"/>
							<label htmlFor='politica' className='checkboxLabel' onClick={onClick}>He leído la política de privacidad</label>
							<br />
							{errors.politica && <span className="supportText error">Por favor lee nuestra política de privacidad.</span>}
						</div>
					</div>
					<button className="button" type="submit">¿Nos reunimos?</button>
				</form>
			</div>
		</section>
	)
}

export default Contacto;