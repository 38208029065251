import logoReact from './assets/icons/icon-react.png';
import logoBootstrap from './assets/icons/icon-boostrap.png';
import logoRails from './assets/icons/icon-rails.png';
import logoPython from './assets/icons/icon-python.png';
import logoDjango from './assets/icons/icon-django.png';
import logoFigma from './assets/icons/icon-figma.png';
import logoMiro from './assets/icons/icon-miro.png';
import logoGit from './assets/icons/icon-git.png';
import logoPostgresql from './assets/icons/icon-postgresql.png';
import logoMongodb from './assets/icons/icon-mongodb.png';
import logoAws from './assets/icons/icon-aws.png';

export const METODOLOGIES = [
	{
		id: 1161091,
		title: 'User Centricity',
		description: 'Enfoque total en usuarios.',
	},
	{
		id: 1161092,
		title: 'Frameworks Ágiles',
		description: 'Ágil para proyectos eficientes.',
	},
	{
		id: 1161093,
		title: 'Design Thinking',
		description: 'Creatividad enfocada en soluciones.',
	},
	{
		id: 1161094,
		title: 'Kanban',
		description: 'Visualiza y gestiona tu trabajo.',
	},
];

export const DEV_TOOLS = [
	{
		id: 1161001,
		image: logoReact,
		title: 'React',
	},
	{
		id: 1161002,
		image: logoBootstrap,
		title: 'Bootstrap',
	},
	{
		id: 1161003,
		image: logoRails,
		title: 'Rails',
	},
	{
		id: 1161004,
		image: logoPython,
		title: 'Python',
	},
	{
		id: 1161005,
		image: logoDjango,
		title: 'Django',
	},
];

export const DESIGN_COLAB_TOOLS = [
	{
		id:	116100991,
		image: logoFigma,
		title: 'Figma',
	},
	{
		id:	116100992,
		image: logoMiro,
		title: 'Miro',
	},
	{
		id:	116100993,
		image: logoGit,
		title: 'Git',
	}
];

export const DATABASE_TOOLS = [
	{
		id: 116100981,
		image: logoPostgresql,
		title: 'PostgreSQL',
	},
	{
		id: 116100982,
		image: logoMongodb,
		title: 'MongoDB',
	},
	{
		id: 116100983,
		image: logoAws,
		title: 'AWS',
	}
];