import logo from '../assets/images/logo-navbar.svg';
import {useRef} from 'react';

const Navbar = ({onClick1, onClick2, onClick3, onClick4, onClick5, onClick6}) => {
	
	return (
		<nav className='navbar'>
			<div>
				<img src={logo} className="navbarLogo" alt="Home" onClick={onClick1}/></div>
				<menu className='menu'>
					<li className='menuItem'><button className='menuButton' onClick={onClick1}>Inicio</button></li>
					<li className='menuItem'><button className='menuButton' onClick={onClick2}>¿Cómo trabajamos?</button></li>
					<li className='menuItem'><button className='menuButton' onClick={onClick3}>Productos y Servicios</button></li>
					<li className='menuItem'><button className='menuButton' onClick={onClick4}>Herramientas y Tecnología</button></li>
					<li className='menuItem'><button className='menuButton' onClick={onClick5}>Sobre nosotros</button></li>
				</menu>
			<div><button className="button shadow" onClick={onClick6}>¿Nos reunimos?</button></div>
		</nav>
	);
}

export default Navbar;