import logo from '../assets/images/logo-hero.svg';
import coding from '../assets/images/coding.svg';
import prototyping from '../assets/images/prototyping.svg';

const Inicio = ({reference}) => {	
	return (
		<section className='section' ref={reference}>
          <div className='flex paddingBlockStart'>
            <img src={logo} className="imageLogo" alt="Logotipo" />
          </div>
          <div className="flex paddingBlockStart">
            <img src={coding} className="image" alt="Coding" />
            <img src={prototyping} className="image" alt="Prototype" />
          </div>
        </section>
	);
}

export default Inicio;