import { SERVICES } from '../services.js';

const AddService = ({id, image, title, alt}) => (
	<div className="card" key={id}>
		<img className="imageService" src={image} alt={alt} />
		<h3 className="subtitleLight service">{title}</h3>
		<a className="link" href="#" id={alt}>ver más</a>
	</div>
);

const Servicios = ({reference}) => {	
	return (
		<section className='section' ref={reference}>
			<h2 className='headline underline'>Productos y servicios</h2>
			<div className='flex marginBlockStart'>
				{SERVICES.map((service) => (
					<AddService key={service.id} {...service}/>
				))}
			</div>
		</section>
	);
}

export default Servicios;