import { TEAM } from '../team.js';

const AddScrollPage = ({id, title, image, description}) => (
	<scroll-page class="scrollCard" id={'scroll-page-'+id}>
		<img className='avatar' src={image} alt={title} />
		<h3 className="subtitleLight">{title}</h3>
		<hr className="hr"/>
		<p className="baseFontSpacing description">{description}</p>
	</scroll-page>
);

const Equipo = ({reference}) => {	
	return (
		<section className='section sectionLessMargin backgroundLogo noScroll' ref={reference}>
			<h2 className='headline underline'>El equipo detrás del éxito</h2>
			<p className='baseFont paragraph'>Somos un grupo de personas apasionadas por la tecnología y la innovación. Nos esforzamos por hacer que la tecnología sea fácil de entender y usar. Conoce a los líderes que nos inspiran en este camino.</p>
			<div className='container witdh75r'>
				<scroll-container class="sliderSlideNoScroll">
					{TEAM.map((team) => (
						<AddScrollPage key={team.id} {...team}/>
					))}
				</scroll-container>
				<div className='flex'>
					<div className="sliderBulletList">
						{TEAM.map((team, index) => (
							<a href={`#scroll-page-${team.id}`} className="sliderBullet anchor" key={index}></a>
						))}
					</div>				
				</div>
			</div>
		</section>
	);
}

export default Equipo;