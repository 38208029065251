import imgLaptop from './assets/icons/icon-laptop.png';
import imgCart from './assets/icons/icon-shopping-cart.png';
import imgStore from './assets/icons/icon-store.png';
import imgUx from './assets/icons/icon-user-experience.png';
import imgAnalytics from './assets/icons/icon-analytics.png';
import imgApi from './assets/icons/icon-api.png';
import imgIpad from './assets/icons/icon-ipad.png';
import imgCode from './assets/icons/icon-code.png';
import imgCloud from './assets/icons/icon-cloud.png';

export const SERVICES = [
	{
		id: 1151141,
		image: imgLaptop,
		title: 'Transformación Digital',
		alt: "transformacion"
	},
	{
		id: 1151142,
		image: imgCart,
		title: 'E-commerce & Marketplace',
		alt: "ecommerce"
	},
	{
		id: 1151143,
		image: imgStore,
		title: 'Sistemas Administrativos',
		alt: 'administracion'
	},
	{
		id: 1151144,
		image: imgUx,
		title: 'Experiencia de usuario (UX/UI)',
		alt: 'experiencia'
	},
	{
		id: 1151145,
		image: imgAnalytics,
		title: 'Analítica',
		alt: 'analitica'
	},
	{
		id: 1151146,
		image: imgApi,
		title: "Creación e Integración de API's",
		alt: 'api'
	},
	{
		id: 1151147,
		image: imgIpad,
		title: 'Aplicaciones Web & Móviles',
		alt: 'aplicaciones'
	},
	{
		id: 1151148,
		image: imgCode,
		title: 'Backend & Frontend',
		alt: 'codigo'
	},
	{
		id: 1151149,
		image: imgCloud,
		title: 'Arquitectura en la nube',
		alt: 'nube'
	},
];