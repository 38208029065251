import estamosListos from '../assets/images/estamos-listos.svg';
import { METODOLOGIES, DEV_TOOLS, DESIGN_COLAB_TOOLS, DATABASE_TOOLS } from '../tools.js';

const AddSlide = ({title, description}) => (
	<li className="slide slideText">
		<p className='subtitle primaryBlue'>{title}</p>
		<p className='baseFont primaryBlue'>{description}</p>
	</li>
);

const AddSlideImage = ({image, title}) => (
	<li className="slide slideImage">
		<img src={image} alt={title} />
	</li>
);

const Herramientas = ({reference}) => {	
	return (
		<section className='section paddingBlockEnd backgroundWhiteShape' ref={reference}>
			<div className='flex'>
				<div>
					<h2 className='headline underline'>Estamos listos para acompañarte</h2>
					<p className='baseFont paragraph'>Tu proyecto es nuestro próximo desafío y estamos preparados para llevarlo a cabo con entusiasmo.  Utilizaremos siempre las mejores herramientas tecnológicas disponibles, asegurando que tu visión sea llevada a cabo de manera excepcional.</p>
				</div>
				<img src={estamosListos} className="boxImage" alt="Coding" />
			</div>
			<div className='container width75p'>
				<div>
					<p className='title secondaryBlue marginBlockStart'>Metologías</p>
					<hr className="hr"/>
				</div>
				<div className="sliderContainer">
					<ul className="sliderSlide">
						{METODOLOGIES.map((methodology) => (
							<AddSlide key={methodology.id} {...methodology} />
						))}
					</ul>
				</div>
			</div>
			<div className='container width75p'>
				<div>
					<p className='title secondaryBlue'>Desarrollo Web</p>
					<hr className="hr"/>
				</div>
				<div className="sliderContainer">
					<ul className="sliderSlide sliderImageSlide">
						{DEV_TOOLS.map((devtools) => (
							<AddSlideImage key={devtools.id} {...devtools} />
						))}
					</ul>
				</div>
			</div>
			<div className='container width75p'>
				<div>
					<p className='title secondaryBlue'>Diseño y Colaboración</p>
					<hr className="hr"/>
				</div>
				<div className="sliderContainer">
					<ul className="sliderSlide sliderImageSlide">
						{DESIGN_COLAB_TOOLS.map((designtools) => (
							<AddSlideImage key={designtools.id} {...designtools} />
						))}
					</ul>
				</div>
			</div>
			<div className='container width75p'>
				<div>
					<p className='title secondaryBlue'>Infraestructura y Base de Datos</p>
					<hr className="hr"/>
				</div>
				<div className="sliderContainer">
					<ul className="sliderSlide sliderImageSlide">
						{DATABASE_TOOLS.map((databasetools) => (
							<AddSlideImage key={databasetools.id} {...databasetools} />
						))}
					</ul>
				</div>
			</div>
		</section>
	);
}

export default Herramientas;